<template>
    <div>
        <Card raised>
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title"> Short story: The Devil Made Me Do It</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h5 class="description">

                    <div class="entry-content">
                      <p>
                        <br />

                      </p>

     <!--                  <youtube :video-id="OuUp6wcrwco"></youtube> -->


                      <center>
                           Push play button below to listen now
                      </center>

<br />

                        <div>
                          <iframe src="https://anchor.fm/david-beaton8/embed" height="132px" width="100%" frameborder="0" scrolling="no"></iframe>
                        </div>

                        <br /><br />

<br />

                 <center>
                   <a href="https://open.spotify.com/show/3RPucZ31WPFEiidNGpvUeo?si=S8xo9RQlSwiLbYQFZ15HUA">
                    <n-button type="primary"     round simple> Listen to it on Spotify </n-button>
                  </a>
                 </center>




                    </div>
              </h5>



            </div>
          </div>

        </Card>

  </div>
  </template>

  <script>

  import Card from "../components/Card";


  import {
    Button,
    Checkbox,
    Radio,
    FormGroupInput,
    Slider,
    Switch
  } from '@/components';

  export default {
    name: "sid",
    bodyClass: "sid-card",
    methods: {
          btnGetYourCopy() {
               alert("hey girls")
          }

    },
    components: {
      Card,

      [Button.name]: Button,

  /*         Navigation,
      BasicElements,
      TabsSection,
      ProgressPagination,
      Notifications,
      Typography,
      JavascriptComponents,
      CarouselSection,
      NucleoIconsSection,
      SignupForm,
      ExamplesSection,
      DownloadSection,
   */
    },
  };


  </script>

