<template>
  <div>
    <!--

      page-header-small  / determines the height of first banner

     -->
    <div
      class="page-header
      page-header-small
      clear-filter"
      filter-color="blue"
    >
      <parallax
        class="page-header-image"
        style="background-image: url('/img/prayer-flags-225.jpg')"
      >
      </parallax>

      <div class="container">
        <div class="content-center brand">
          <h1 class="title">david j. beaton</h1>

<!--           <img class="n-logo" src="/img/now-logo.png" alt="" />
 -->
          <h1 class="h1-seo"></h1>
          <h3></h3>
        </div>

        <h6 class="category category-absolute"></h6>
      </div>
    </div>


    <div class="section section-about-us">


      <div class="container">
        <!-- -->
        <Sid></Sid>

        <!-- -->
        <Motso></Motso>

        <Devil></Devil>

        <Oscar></Oscar>

        <div class="separator separator-primary"></div>


      </div>
    </div>

  </div>
</template>
<script>
import { Parallax } from "@/components";

import {
  Button,
  Checkbox,
  Radio,
  FormGroupInput,
  Slider,
  Switch
} from '@/components';

/* import BasicElements from "./components/BasicElementsSection";
import Navigation from "./components/Navigation";
import TabsSection from "./components/Tabs";
import ProgressPagination from "./components/ProgressPagination";
import Notifications from "./components/Notifications";
import Typography from "./components/Typography";
import JavascriptComponents from "./components/JavascriptComponents";
import CarouselSection from "./components/CarouselSection";
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";
 */


import Card from "./components/Card";
import  Sid from './cards/sid.vue';
import  Motso from './cards/motso.vue';
import  Oscar from './cards/oscar.vue';
import  Devil from './cards/devil.vue';




export default {
  name: "index",
  bodyClass: "index-page",
  methods: {
        btnGetYourCopy() {

             alert("hey girls")
        }

  },
  components: {
    Devil,
    Oscar,
    Motso,
    Sid,
    //Card,
    Parallax,

    [Button.name]: Button,

/*  Navigation,
    BasicElements,
    TabsSection,
    ProgressPagination,
    Notifications,
    Typography,
    JavascriptComponents,
    CarouselSection,
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
 */
  },
};
</script>
<style>
.container1 {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.description {
  color: black;
}

.img_book {
  box-shadow: 0 0.5rem 1.625rem rgba(0, 0, 0, 0.45);
  margin-bottom: 2rem;
  margin-right: 1rem;
}

</style>
