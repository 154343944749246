<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/mist-trees-225kb.jpg')"
      >
      </parallax>


    </div>
    <div class="section">
      <div class="container">

        <!-- <div class="button-container">
          <a href="#button" class="btn btn-primary btn-round btn-lg">Follow</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div> -->
<article class="post-full post page no-image">

            <header class="post-full-header">
                <h1 class="post-full-title"></h1>
            </header>


<div class="container">
        <h4 class="description">
          Lyrics I wrote from my time with Jetpilot.

        </h4>

</div>

</article>

<article class="post-full post page no-image">

            <header class="post-full-header">
                <h1 class="post-full-title">Lyrics</h1>
            </header>


<div class="container">
  <div class="row">
    <div class="col-12 col-md-6">
 <h4 id="album-awake-and-dreaming">Album: Awake and Dreaming</h4>

<p><a href="#Breathing">1. Breathing</a><br>
<a href="#AW2">2. All About You</a> <br>
<a href="#AW3">3. A Lotta Nerve</a> <br>
<a href="#AW4">4. I Pray</a> <br>
<a href="#AW5">5. Sister</a> <br>
<a href="#AW6">6. Till We Have Faces</a> <br>
<a href="#AW7">7. There Must Be More</a> <br>
<a href="#AW8">8. Over My Shoulder</a> <br>
<a href="#AW9">9. Awake and Dreaming</a> <br>
<a href="#AW10">10. The Rain</a> <br>
<a href="#AW11">11. From the Outside In</a></p>


    </div>
    <div class="col-12 col-md-6">
      
      <br/>
      <br/>
      <br/>
<iframe src="https://open.spotify.com/embed/album/5CDlqFf8gOrtXTC0hN1Cg9" width="100%" height="300" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>

    </div>
  </div>

</div>



  <div class="row">
    <div class="col-12 col-md-6">

<h4 id="ep-never-alone">EP: Never Alone</h4>
<p><a href="#NA1">1. Never Alone</a> <br>
<a href="#NA2">2. Never Gonna Find</a> <br>
<a href="#NA3">3. Whats in a Moment</a></p>


    </div>
    <div class="col-12 col-md-6">

      <br/>
      <br/>
      <br/>

<iframe src="https://open.spotify.com/embed/track/2Twox5ybmzfcJEcEcUJh4I" width="100%" height="108" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>      


    </div>
  </div>

      <br/>
      <br/>
      <br/>


<h4 id="ep-never-alone">Music Video For : Awake and Dreaming</h4>


      <div class="container1">
        <iframe
          src="https://www.youtube.com/embed/DUkySbX3kpw"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          class="video"
        ></iframe> 
        </div>


    <section class="post-full-content">



      <br/>
      <br/>
      <br/>



<h4 id="-breathing"><a name="Breathing"></a> Breathing</h4>


<p>Breathing in, yeah, breathing out<br>
I’m waking up and finding out<br>
I’m so far; how far’s bizarre</p>

<p>Behind the years all stacked and checked<br>
And those ahead seem wasted breath<br>
And they seem, to me, yeah me <br>
That time goes by so fast</p>

<p>Breathing in and breathing out<br>
Have I wasted years, in living blind?<br>
Chasing lies, ignoring time, away?<br>
Breathing in and breathing out<br>
Have I wasted years, in living blind?<br>
Chasing lies, ignoring time, away?</p>

<p>I’ve crossed the line there’s no way back<br>
Forgot I was moving, now I’m off track<br>
And it seems, to me, yeah me</p>

<p>I’ve wasted time, can’t pay that debt<br>
Find the right thing, do that next<br>
Is the key, for me, yeah, me  <br>
And time goes by so fast</p>

<p>Breathing in and breathing out<br>
Have I wasted years in living blind?<br>
Chasing lies, ignoring time, away?</p>

<p>Breathing in and breathing out<br>
Have I wasted years, in living blind?<br>
Chasing lies, ignoring time, away?</p>

<p>Wasted all in breathing out<br>
Wasted all in breathing in<br>
Chasing lies, ignoring time, away<br>
Breathing in and breathing out<br>
It’s wasted all, in living blind<br>
Chasing lies, ignoring time, away</p>

<h3 id="-all-about-you"><a name="AW2"></a> All About You</h3>

<p>Walking down a highway, blind<br>
A trail of selfish life behind<br>
With Feeling as the place to hide,<br>
You know you’re justified<br>
Experience is the only guide<br>
It’s all about you<br>
It’s all about you</p>

<p>Now you’re hearing lots of voices<br>
Making lots of noises, yeah<br>
Screaming their salvation<br>
Screaming condemnation<br>
Screaming for attention, screaming:<br>
It’s all about you<br>
It’s all about you</p>

<p>Now I’m living like I’m never gonna have to<br>
Give an answer for the way I’m living, who I’m kidding, I don’t really know<br>
Maybe digging deeper, chases faking, waking sleepers, finding truth:<br>
Find it and wake</p>

<p>Feeling good and on our way<br>
Just another sunny blue day<br>
Piper and the reaper always someone else’s keeper<br>
Never gonna drop our way<br>
It’s all about you<br>
It’s all about you</p>

<p>Now I’m living like I’m never gonna have to<br>
Give an answer for the way I’m living, who I’m kidding, I don’t really know<br>
Maybe digging deeper, chases faking, waking sleepers, finding truth:<br>
Find it and wake</p>

<p>Now I’m living like I’m never gonna have to<br>
Give an answer for the way I’m living, who I’m kidding, I don’t really know<br>
Maybe digging deeper, chases faking, waking sleepers, finding truth<br>
Find it and wake</p>

<h3 id="-a-lotta-nerve"><a name="AW3"></a> A Lotta Nerve</h3>

<p>Out on her own and she’s living<br>
Just for today<br>
Such a lot of things to do and still<br>
So much to say<br>
Out on your motorbike riding<br>
Harder every day<br>
In neon boots you just don’t care<br>
What the others say</p>

<p>So self-assured now baby<br>
And on your way<br>
So self-assured now baby<br>
You got, you got, you got a lotta of nerve you got. . .</p>

<p>. . . a way<br>
Don’t ya change?<br>
You got a lotta of nerve<br>
Don’t ya change?<br>
You got a lotta of nerve<br>
Don’t ya change?</p>

<p>Her parties rock with crazy friends<br>
All on their way<br>
You always bop, like no one sees,<br>
Up on any stage<br>
Her art’s her own, a mix, Picasso and New-Wave<br>
Blonde and blue-eyed at the beach with a bucket and spade</p>

<p>So self-assured now baby<br>
And on your way<br>
So self-assured now baby<br>
You got, you got, you got a lotta of nerve you got. . .</p>

<p>… a way<br>
Don’t ya change?<br>
You got a lotta of nerve<br>
Don’t ya change?<br>
You got a lotta of nerve<br>
Don’t ya change</p>

<p>You suit yourself; you’re three years-old<br>
And Life is fun, there’s more to come<br>
No time to cry, just wipe your eyes<br>
‘Cause life is on, you gotta run<br>
You’re three years-old<br>
And Life is fun</p>

<p>You got away<br>
You’ve got away<br>
You got a lotta nerve<br>
Don’t change!<br>
You got a lotta nerve<br>
Don’t change!</p>

<h3 id="-i-pray"><a name="AW4"></a> I Pray</h3>

<p>Can’t sleep tonight or drown the doubt<br>
Of the voice that whispers “loser”<br>
A million thoughts crowd out the light<br>
And leave me my accuser</p>

<p>And all I feel is that I don’t know<br>
While words of wisdom make it so<br>
And all I have are questions why<br>
Where every answer seems a lie</p>

<p>And so I pray for hope and life to live<br>
And I walked away<br>
Will I ever heal again?<br>
The weight of life too hard to hold<br>
I walked away<br>
Will I ever heal again?</p>

<p>Scars they run like river veins<br>
Through the time and life still healing<br>
With the whispered thoughts and the phantom pains<br>
Old scenes alive and they’re breathing</p>

<p>And all I felt now comes to mind<br>
And laughs at wisdom and it calls it blind<br>
‘Cause still no answers pay the night<br>
To share the lies that hide inside</p>

<p>And so I pray for hope and life to live<br>
And I walked away<br>
Will I ever heal again?<br>
The weight of life too hard to hold<br>
I walked away<br>
Will I ever heal again?</p>

<p>And so I pray for hope and life to live<br>
And I walked away<br>
Will I ever heal again?<br>
The weight of life too hard to hold<br>
I walked away<br>
Will I ever heal again?</p>

<h3 id="-sister"><a name="AW5"></a> Sister</h3>

<p>Sister where’s your heart tonight?<br>
Where’s your mind your thoughts tonight?<br>
And the smile inside of you,<br>
The light inside of you?</p>

<p>Are you walking yesterdays,<br>
Tomorrow’s sunrise in your eyes?<br>
Or with me here and now<br>
With me here and now?</p>

<p>Sister where’s your hope tonight,<br>
Where’s your heart your guiding light,<br>
To find your way alone,<br>
To find your way back home?</p>

<p>Sister oh so far away<br>
You’re living night I’m living day<br>
With all the years between<br>
All the years between</p>

<p>Sister where’s your hope tonight,<br>
Where’s your heart your guiding light,<br>
To find your way alone,<br>
 To find your way back home?</p>

<p>Sister where’s your hope tonight,<br>
Where’s your heart your guiding light,<br>
To find your way alone,<br>
To find your way back home?</p>

<h3 id="-till-we-have-faces"><a name="AW6"></a> Till We Have Faces</h3>

<p>Been so many places, and I’ve travelled so far<br>
I’ve seen so many faces, I forget which one you are</p>

<p>I’ve been to extremes, as I reached for stars<br>
Seen so many dreams, I forget which one you are</p>

<p>Lost you in the crowd, lost you in the crowd<br>
I couldn’t see<br>
I lost you on the way, lost you on the way<br>
To finding me</p>

<p>‘Till we have faces can’t find the words<br>
‘Till we have faces can’t meet the mind<br>
You are<br>
‘Till we have faces can’t find the words<br>
‘Till we have faces can’t reach the mind<br>
You are</p>

<p>I lost you in the crowd, lost you in the crowd<br>
I couldn’t see<br>
I lost you on the way, lost you on the way<br>
To finding me</p>

<p>I lost you in the crowd, in the crowd<br>
I couldn’t see<br>
I lost you on the way, on the way<br>
To finding me</p>

<p>Losing you, I’m losing you<br>
Losing you, I’m losing me<br>
And now losing you, I’m losing me</p>

<h3 id="-there-must-be-more"><a name="AW7"></a> There Must Be More</h3>

<p>Dressed up, silver words on our lips<br>
We’re laughing, joking, saying “I do”<br>
You know it’s the way, eclipse<br>
With candy coated smile, “I do”</p>

<p>We talk about the things that we do<br>
And decorate a word maybe two<br>
You say it’s all good, all true<br>
A figment of your point of view</p>

<p>And there must be more than this<br>
More than candy-coated picture white lies, big smile<br>
Waving, playing “look at me”<br>
Oh . . .</p>

<p>We’re laughing, singing “I love you”<br>
Up close and smiling words apart<br>
A snapshot of a moment gone soon<br>
That’s worlds away till Death us part</p>

<p>And there must be more than this<br>
More than candy-coated picture white lies, big smile<br>
Waving, playing “look at me”</p>

<p>And there must be more than a lie<br>
More than a reason, getting by to get away with this<br>
It’s such a funny life to lead<br>
Oh . . .</p>

<p>We’re waving and we’re saying goodbye<br>
“You gotta live your life” you say<br>
And then we drive on, and try<br>
To live our lives our own old way</p>

<h3 id="-over-my-shoulder"><a name="AW8"></a> Over My Shoulder</h3>

<p>Its pot-holed roads, with limousine motorcades<br>
And the pin-suit tribe, growing fat on taking bribes<br>
An AK-47, making all your friends<br>
Your first born son yeah, now bring me my machine gun</p>

<p>‘Cause it’s Africa<br>
Yes it’s Africa<br>
Still it’s Africa<br>
Over my shoulder it’s Africa<br>
Over my shoulder again<br>
Over my shoulder it’s Africa<br>
Over my shoulder again</p>

<p>A tin-shack child alone and a no-parent home<br>
Where might makes right, he’d better learn how to fight<br>
‘Cause here crime pays, but it’s minimum wage<br>
And there’s always money for war, just not the poor</p>

<p>‘Cause it’s Africa<br>
Yes it’s Africa<br>
Over my shoulder it’s Africa<br>
Over my shoulder again<br>
Over my shoulder it’s Africa<br>
Over my shoulder again</p>

<p>Over my shoulder it’s Africa<br>
Over my shoulder again<br>
Over my shoulder it’s Africa<br>
Over my shoulder again</p>

<h3 id="-awake-and-dreaming"><a name="AW9"></a> Awake and Dreaming</h3>

<p>There’s a rhythm ride, on the street tonight<br>
And she’s walking tall to the beat just right<br>
‘Cause it is London, Paris baby even Rome<br>
In her high-heel boots<br>
And on her mobile phone<br>
‘Cause she’s a high-stakes girl in a busy world<br>
And on the roulette wheel she’ll win every deal<br>
She is savoir-faire and a billionaire<br>
The fashion queen of the city dream</p>

<p>I said “Awake, I’m dreaming”<br>
I said “Awake, I’m dreaming”<br>
Awake and dreaming<br>
I said “Awake, I’m dreaming”<br>
There’s a light to find my way<br>
There’s a light to find my way</p>

<p>And as I look around at the things I see<br>
So many pretty things all made to please<br>
And she catches me with the way it feels<br>
And she catches me with the web she weaves<br>
But there’s a voice inside, a soft remind<br>
“Don’t waste this life”, “don’t waste this life”<br>
‘Cause the rhythm ride, on the street tonight<br>
Holds the strings of night, keeps the dream alive</p>

<p>I said “Awake, I’m dreaming”<br>
I said “Awake, I’m leaving”<br>
Awake and dreaming<br>
I said “Awake, I’m leaving”</p>

<p>And there’s a light, and there’s a light, and there’s a light<br>
And there’s a light, and there’s a light, and there’s a light</p>

<p>‘Cause if I don’t go I will never know<br>
When the time is right - and the time is right<br>
And left to slowly die, yeah the voice inside<br>
“Don’t waste this life”, “don’t waste this life”</p>

<p>I said “Awake”<br>
I said “Awake”</p>

<h3 id="-the-rain"><a name="AW10"></a> The Rain</h3>

<p>“Where have you been?”  <br>
“Oh, I’ve been going to and fro<br>
Been walking high and low”, He said,<br>
He smiled and said:<br>
“All so easy when it’s good,<br>
Life is as it should be,<br>
It’s all it can be”</p>

<p>Oh, now all I had was carried on<br>
Of what I had it’s all but gone away, away  <br>
“Oh now skin for skin” he whispered slow<br>
And left to give me poison in my bones, my bones</p>

<p>The whirlwind rages on, there’s lightning in the sky<br>
And the rain’s a long time coming, still no answer, no reply<br>
I said, the rain<br>
I said, the rain</p>

<p>Oh they say my world is over now<br>
They say it’s all but done<br>
I’m waiting for the rain, the rain</p>

<p>Oh and bitter are these thorns<br>
And better I was left unborn<br>
Than to see this day</p>

<p>Oh my friends all gather round<br>
Poor comforts cast me down<br>
They cast me down<br>
Me down</p>

<p>Oh, they speak to find design<br>
To soothe their own alarm<br>
They speak to find,<br>
They speak to find</p>

<p>And for every woe that falls<br>
They have to find give a cause<br>
A reason why<br>
A rhyme in “why?”</p>

<p>And the whirlwind rages on, there’s lightning in the sky<br>
And the rain’s a long time coming, still no answer, no reply<br>
I said, the rain<br>
I said, the rain</p>

<p>Oh they say my world is over now, they say it’s all but done<br>
Waiting for the rain, the rain</p>

<p>I said I’m waiting, I’m waiting, waiting for the rain, the rain</p>

<p>And the whirlwind rages on, there’s lightning in the sky<br>
And it’s asking me for answers, to which I can’t reply</p>

<p>But, oh, the rain<br>
Oh, the rain<br>
Oh, the rain  <br>
The Rain</p>

<h3 id="-from-the-outside-in"><a name="AW11"></a> From the Outside In</h3>

<p>The subway walls, all smiles and laughing<br>
In a billboard world, surrounds and holds his eyes<br>
And, walking on, climbing stairs and turning<br>
Corners now, he’s elbowed sideways</p>

<p>And so it seems, just in the way, still just okay, his world runs grey</p>

<p>The street lights shine, with yellow halos now<br>
And through the rain, he makes his way alone<br>
It’s three floors up, a pause and turn the key now<br>
Breathing hard, he feels the age</p>

<p>And so it seems, just in the way, still just okay, his world runs grey<br>
And his day is done, another year along a narrow way<br>
And all alone he wonders at the way<br>
The world around him, now so far away<br>
All alone, and wondering<br>
When it began, from the outside in</p>

<p>The TV flickers, and floating pictures trespass<br>
While faces scroll, grey before him<br>
And so many whisper, now passed on they<br>
Tell of their todays, for our tomorrow</p>

<p>Where it seems, just in the way, still just okay, his world runs grey<br>
And his day is done, another year along a narrow way<br>
And all alone he wonders at the way<br>
The world around him, now so far away<br>
Now all alone, I’m wondering<br>
All alone, and wondering<br>
All alone, and wondering</p>

<p>All alone, from the outside in</p>

<h3 id="-never-alone"><a name="NA1"></a> Never Alone</h3>

<p>Waiting for life passing time<br>
Lies dine with truth in her mind<br>
Like wheat and like tares<br>
They will grow into fears<br>
She’s feeling forever alone<br>
Now she’s believing there’s no one else bleeding<br>
She’s feeling forever alone</p>

<p>Know that you’re never alone in this lifetime<br>
Just know that you’re never alone<br>
Weigh all your years<br>
And the prayers left behind you<br>
And find that you’re never alone</p>

<p>Waking late years in her mind<br>
Watching her life, slow rewind<br>
She weighs all her years<br>
And the prayers left behind her<br>
And finds that she’s never alone</p>

<p>Know that you’re never alone in this lifetime<br>
Just know that you’re never alone<br>
Weigh all your years<br>
And the prayers left behind you<br>
And find that you’re never alone</p>

<h3 id="-never-gonna-find"><a name="NA2"></a> Never Gonna Find</h3>

<p>Looking for the answers in a place you’ll never find them<br>
Don’t you know you’ll never find them,<br>
Don’t you know you’ll never see them</p>

<p>Cause you’re never gonna find, never gonna find<br>
No you’re never gonna find them looking there</p>

<p>Weekend washes in again, it’s Friday night she’s out again<br>
Holding out for answers in romance I’m taking chances</p>

<p>But she’s never gonna find, never gonna find<br>
No she’s never gonna find it looking there</p>

<p>Maybe it will take a little time<br>
Baby it’s gonna take all of your time</p>

<p>Swimming in this sea of faded smiles and painted roses yes<br>
She brings to mind a girl I knew, half broken seeking answers</p>

<p>But she’s never gonna find, never gonna find<br>
No she’s never gonna find them looking there</p>

<p>Maybe it will take a little time<br>
Baby it’s gonna take more than your time</p>

<p>Swirling through the center of a million different people<br>
She is slowly feeling lonely, she is asking God “if only?”</p>

<p>But she’s never gonna find, never gonna find<br>
No she’s never gonna find it looking there</p>

<p>Maybe it will take a little time<br>
Baby it’s gonna take more than your time</p>

<p>Cause you’re never gonna find, never gonna find<br>
No you’re never gonna find it looking here</p>

<h3 id="-whats-in-a-moment"><a name="NA3"></a> What’s in a Moment</h3>

<p>Lived in the moment<br>
Felt that perfect sense<br>
Well what’s in a moment<br>
When I’ve a life to live</p>

<p>Which way is up and tell me<br>
Which way is down<br>
Lost my perspective in this<br>
Relative frame of mind</p>

<p>What’s in a moment<br>
When I’ve a life to live<br>
More than a feeling<br>
I need a life to live</p>

<p>Lived in the moment of<br>
A second so intense<br>
Well what’s in a moment<br>
When I’ve a life to live</p>

<p>With a white cane for feeling<br>
I’ve felt my way through life<br>
Searching for healing and a piece<br>
Of Paradise</p>

<p>What’s in a moment<br>
When I’ve a life to live<br>
More than a feeling<br>
I need a life to live</p>

            </section>

        </article>

      
      
      </div>
    </div>
  </div>
</template>
<script>
//import { Tabs, TabPane } from "@/components";


export default {
  name: "about",
  bodyClass: "profile-page",
  data() {
    return {
      componentKey: 0,
    };
  },  
  components: {
    //Tabs,
    //TabPane
    
  },


};
</script>
<style>
.container1 {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>
