<template>
  <div>
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/prayer-flags-225.jpg')"
      >
      </parallax>


    </div>
    <div class="section">
      <div class="container">
        <h3 class="title">Writing</h3>
        <h5 class="description">
                 Below are some of the writing projects I have completed over the last few years.
        </h5>


        <Sid> </Sid>

        <!-- -->

        <Motso></Motso>


        <!-- -->
        <Oscar></Oscar>


        <!-- -->
        <Devil></Devil>


      </div>
    </div>
  </div>
</template>
<script>
import  Sid from './cards/sid.vue';

import  Motso from './cards/motso.vue';

import  Oscar from './cards/oscar.vue';

import  Devil from './cards/devil.vue';



import {
  Button,
  Checkbox,
  Radio,
  FormGroupInput,
  Slider,
  Switch
} from '@/components';

export default {
  name: "writing",
  bodyClass: "profile-page",
  data() {
    return {
      componentKey: 0,
    };
  },
  components: {
    Devil,
    Oscar,
    Motso,
    Sid,
    [Button.name]: Button,

  },


};
</script>
<style></style>
