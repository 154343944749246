<template>
  <div>
    <Card raised>

      <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Oscar & the Blue Men</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h5 class="description">

                    <div class="entry-content">
                      <p>
                        <img
                          style="width: 250px"
                          class="img_book"
                          border=""
                          align="left"
                          src="/img/index/oscar_and_the_blue_men.png"
                          alt="image"
                        /><br />
                        Oscar and the Blue Men is the story of Oscar the
                        Orangutan who on his way to the moon pushes a button he
                        should not. As a result he is violently ejected from his
                        rocket-ship. He lands at the North Pole and here he
                        meets A Blue man who helps him get back home again.
                      </p>

     <!--                  <youtube :video-id="OuUp6wcrwco"></youtube> -->

                        <div class="container1">
                          <iframe
                            src="https://www.youtube.com/embed/OuUp6wcrwco?ecver=1"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen=""
                            class="video"
                          ></iframe>
                         </div>


                    </div>
              </h5>

              <h5 class="description">
                <br />
                <br />

                 <center>
                  <a href="https://www.groep7-selfpublish-books.co.za/product/oscar-and-the-blue-men-david-j-beaton/"  target="_blank">
                      <n-button type="primary"     round simple> Get Your Copy In South Africa  </n-button>
                     </a>

                     <a href="https://www.amazon.com/Oscar-Blue-Men-David-Beaton/dp/0620683007" target="_blank">
                      <n-button type="primary"     round simple> Your Copy On Amazon </n-button>
                     </a>

                 </center>

                <br />

              </h5>


            </div>
          </div>



    </Card>
</div>
</template>

<script>

import Card from "../components/Card";


import {
  Button,
  Checkbox,
  Radio,
  FormGroupInput,
  Slider,
  Switch
} from '@/components';

export default {
  name: "sid",
  bodyClass: "sid-card",
  methods: {
        btnGetYourCopy() {
             alert("hey girls")
        }

  },
  components: {
    Card,

    [Button.name]: Button,

/*         Navigation,
    BasicElements,
    TabsSection,
    ProgressPagination,
    Notifications,
    Typography,
    JavascriptComponents,
    CarouselSection,
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
 */
  },
};


</script>

