<template>
          <!-- -->
          <Card raised>
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto text-center">
                <h2 class="title">Latest Book: Sid the Rock'n Roll Shrew</h2>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 ml-auto mr-auto">
                <h5 class="description">
                  <img
                    style="width: 250px"
                    class="img_book"
                    border=""
                    align="left"
                    src="/img/index/sid-255.jpg"
                    alt="image"
                  />

                  Sid the shrew is a champion dreamer.  If he’s not imagining playing guitar before
                  thousands of screaming fans, then he’s daydreaming about fighting the cobra rumoured
                  to be stalking the community. Fortunately, Sid’s best friend Bliz the lizard helps
                  keep his head out of the clouds, as they skateboard down storm water drains and
                  scavenge for parts. Then one night after a scavenging mission gone wrong, the two
                  friends find themselves in Lurkervale, the shadowy community down near the river.
                  That evening, Sid finds an electric guitar and a drummer—almost everything he needs
                  to start a band. But, as Sid will learn, turning visions into reality is hard, and
                  pursuing your dreams can make dangerous enemies.

                </h5>


                <h5 class="description">
                  <br />
                  <br />

                   <center>
                     <a href="https://www.groep7-selfpublish-books.co.za/product/sid-the-rock-n-roll-shrew-david-j-beaton/"  target="_blank">
                      <n-button type="primary"     round simple> Get Your Copy In South Africa  </n-button>
                     </a>

                     <a href="https://www.amazon.com/Rockn-Roll-Shrew-David-Beaton/dp/1991245505" target="_blank">
                      <n-button type="primary"     round simple> Your Copy On Amazon </n-button>
                     </a>

                   </center>
                  <br />

                </h5>


              </div>
            </div>
          </Card>
  </template>

<script>

import Card from "../components/Card";


import {
  Button,
  Checkbox,
  Radio,
  FormGroupInput,
  Slider,
  Switch
} from '@/components';

export default {
  name: "sid",
  bodyClass: "sid-card",
  methods: {
        btnGetYourCopy() {
             alert("hey girls")
        }

  },
  components: {
    Card,

    [Button.name]: Button,

/*         Navigation,
    BasicElements,
    TabsSection,
    ProgressPagination,
    Notifications,
    Typography,
    JavascriptComponents,
    CarouselSection,
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
 */
  },
};


</script>
