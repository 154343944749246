<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/mist-trees-225kb.jpg')"
      >
      </parallax>
      <div class="container">
        <div class="photo-container">
          <img src="/img/about/me.jpg" alt="" />
        </div>
        <h3 class="title">David J. Beaton</h3>

        <!--
  <p class="category">Creator</p>
     <div class="content">
          <div class="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div class="social-description">
            <h2>26</h2>
            <p>Comments</p>
          </div>
          <div class="social-description">
            <h2>48</h2>
            <p>Bookmarks</p>
          </div>

        </div> -->
      </div>
    </div>
    <div class="section">
      <div class="container">


        <!-- <div class="button-container">
          <a href="#button" class="btn btn-primary btn-round btn-lg">Follow</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
 -->

        <h3 class="title">About me</h3>
        <h5 class="description">
          David currently works from home as an independent software developer.
          He lives alone with three electric guitars, two motorbikes, and a room
          full of sound equipment. Previous projects he has been involved with
          include the production of an album for his former rock band, Jetpilot,
          as well as self-publishing a children’s book—Oscar and the Blue
          Men—for his niece and nephews who live in Australia. He wrote Catching
          Motso because he was interested in how a single person would cope with
          the sudden responsibility of caring for a child.
        </h5>

      <div class="container">


  <!--       <div class="button-container">
          <a href="#button" class="btn btn-primary btn-round btn-lg">Follow</a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            href="#button"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div> -->


        <h3 class="title">Contact</h3>
        <h5 class="description">

<center>My mobile number: +27 (0) 83 335 8812     </center>

<center>For enquiries and questions email me: djbeaton (at) gmail dot com.  </center>

        </h5>


      </div>


      </div>
    </div>
  </div>
</template>
<script>
//import { Tabs, TabPane } from "@/components";


export default {
  name: "about",
  bodyClass: "profile-page",
  data() {
    return {
      componentKey: 0,
    };
  },
  components: {
    //Tabs,
    //TabPane

  },


};
</script>
<style></style>
