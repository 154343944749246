<template>
  <div>
    <Card raised>
    <div class="row">
      <div class="col-md-8 ml-auto mr-auto text-center">
        <h2 class="title"> Catching Motso</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8 ml-auto mr-auto">
        <h5 class="description">
          <img
            style="width: 250px"
            class="img_book"
            border=""
            align="left"
            src="/img/index/catching_motso.jpg"
            alt="image"
          />

          Looking after his sister’s adopted daughter was never part of
          Shane Collins’ plan. Changing the world and becoming the next
          Bill Gates—that was the plan. Writing software, living on his
          own and being awkward around women, these are things he knows
          about. But taking care of a four-year-old girl of colour . . .
          well there isn’t a manual or FAQ for that as far as he knows.
        </h5>

        <h2 class="title">Reader Reviews</h2>

        <h5 class="description">
          <i>
            “A heart-warming story of a self-absorbed young man, a little
            orphan girl and the events that help to change him into a
            person of depth and feeling. This very human story moves one
            as tragedy and the hard knocks of life take their toll. But
            it’s the resilience and generosity in the human spirit that
            shines through and wins the day. And that leaves one with
            feeling of satisfaction, having met and known Shane and
            Motso.”</i
          >
          <br />
          <b>Anthony Duigan</b> - journalist & communications executive.
        </h5>

        <h5 class="description">
          <i>
            “Catching Motso invites us on a journey. Within its pages we
            discover sorrow entwined with joy, doubt bathed in hope,
            isolation grappling with connection, and one man’s search for
            meaning in the tumult of loss. In this, Shane’s story, we find
            reflections of our own.”
          </i>
          <br />
          <b>Rachel Richardson</b> - literary editor.
        </h5>


        <h5 class="description">
          <br />
          <br />

           <center>
            <a href="https://www.groep7-selfpublish-books.co.za/product/catching-motso-david-j-beaton"  target="_blank">
                      <n-button type="primary"     round simple> Get Your Copy In South Africa  </n-button>
                     </a>

                     <a href="https://www.amazon.com/Catching-Motso-David-J-Beaton/dp/0620820047" target="_blank">
                      <n-button type="primary"     round simple> Your Copy On Amazon </n-button>
                     </a>

           </center>
          <br />

        </h5>


      </div>
    </div>
  </Card>
</div>
</template>

<script>

import Card from "../components/Card";


import {
  Button,
  Checkbox,
  Radio,
  FormGroupInput,
  Slider,
  Switch
} from '@/components';

export default {
  name: "sid",
  bodyClass: "sid-card",
  methods: {
        btnGetYourCopy() {
             alert("hey girls")
        }

  },
  components: {
    Card,

    [Button.name]: Button,

/*         Navigation,
    BasicElements,
    TabsSection,
    ProgressPagination,
    Notifications,
    Typography,
    JavascriptComponents,
    CarouselSection,
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
 */
  },
};


</script>

