/*!
 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================
 */
import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";

// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from "./router";
//import router from "./router-test";

import NowUiKit from "./plugins/now-ui-kit";

Vue.config.productionTip = false;

// G-5J8V92JMJP
Vue.use(VueGtag, {
   // --------------------------------------------------------------
   //  2023-09-22 - changed this to direct to the new tag
   // --------------------------------------------------------------
   // config:  { id: "UA-131371181-1"
   config:  { id: "G-5J8V92JMJP"
      }
    },
   router
);


Vue.use(NowUiKit);

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
