<template>



  <navbar position="fixed"
            type="primary"
          :transparent="transparent"
          :color-on-scroll="colorOnScroll"
            menu-classes="ml-auto">
    <a class="navbar-brand" href="#">Navbar</a>

    <template slot-scope="{}">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        david j. beaton
      </router-link>
    </template>


    <template slot="navbar-menu">
      <li class="nav-item ">
        <router-link  class="nav-link" to="/">
          Home  <span class="sr-only">(current)</span>
        </router-link>
      </li>

      <li class="nav-item ">
        <router-link  class="nav-link" to="/writing">
          Writing
        </router-link>
      </li>

      <li class="nav-item ">
        <router-link  class="nav-link" to="/music">
          Music
        </router-link>
      </li>

      <li class="nav-item ">
        <router-link  class="nav-link" to="/about">
          About
        </router-link>
      </li>

<!--
      <li class="nav-item">
        <a class="nav-link" href="/buy.html">Buy </a>
      </li>

 -->


  </template>





  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    //NavbarToggleButton,
    /// DropDown,
    /// NavLink,
    [Popover.name]: Popover,
  },
};
</script>

<style scoped></style>
