import Vue from 'vue';
import Router from 'vue-router';

import Index from './pages/Index.vue';

import StarterNavbar from './layout/StarterNavbar.vue';
import StarterFooter from './layout/StarterFooter.vue';

import About from './pages/About.vue';
import Music from './pages/Music.vue';
import Writing from './pages/Writing.vue';


Vue.use(Router);

export default new Router({
    /*
     * linkExactActiveClass:
     *       Globally configure <router-link> default active class for exact matches. Also see router-link.
     * */
    // linkExactActiveClass: 'active',

  routes: [
    {
      path: '/writing',
      name: 'writing',
       components: { default: Writing, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
    },


    {
      path: '/music',
      name: 'music',
       components: { default: Music, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 ,transparent: true},
        footer: { backgroundColor: 'black' }
      },
    },


   {
      path: '/about',
      name: 'about',
       components: { default: About, header: StarterNavbar, footer: StarterFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      },
    },

    {
      path: '/',
      name: 'index',
      components: {
        default: Index,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        header: { colorOnScroll: 400,backgroundColor: 'black' },
        footer: { backgroundColor: 'black' }
/*  */      }
    },



  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

